const state = {
    access_token: ''
  }
  
  const mutations = {
    update_token:(state, token) => {
      state.access_token = token
    },
  }
  
  const actions = {
    
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }