
const state = {
  name: 'erc',
  baseColor: '#ee714a',
  twoColor: '#fdd247'
}

const mutations = {
  
}

const actions = {
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
