import Vue from 'vue';
import App from './App.vue';
import {router} from './router';
import store from './store';//仓库
import Bridge from './utils/bridge.js'
//引入 vant 所有的数据组件
import Vant  from 'vant' ;
import 'vant/lib/index.css';

import 'lib-flexible/flexible'

import VueCanvasPoster from 'vue-canvas-poster'
Vue.use(VueCanvasPoster)



Vue.config.productionTip = false
Vue.use(Vant)

Vue.prototype.$bridge = Bridge

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
